import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayjslocale from 'dayjs/locale/en-nz';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import judges from './test-data/judges.json';
import { Button, FormControl, InputLabel, MenuItem, Select as MaterialSelect } from '@mui/material';

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import './Empanelment.scss';
import JudgeDropdown from './JudgeDropdown';
import { withRemote } from '../../utils/withRemote';
import './month-picker.scss'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, withStyles } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import Select from 'react-select'
import SittingOptions from './SittingOptions';
import { DefaultSnackbar } from '../../components/SnackBars';
dayjs.locale(dayjslocale);

const SIZE_OPTIONS = [1, 3, 5, 7];

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: "white",
		},
		'&:nth-of-type(even)': {
			backgroundColor: "#F2F5FC",
		},
	},
}))(TableRow);

function Empanelment(props) {

	const [judges, setJudges] = useState([]);
	const [courts, setCourts] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [sittings, setSittings] = useState([]);

	const [numberOfCourtrooms, setNumberOfCourtrooms] = useState(3);

	const [selectedMonth, setSelectedMonth] = useState(dayjs());
	// const [panelSize, setPanelSize] = useState('');

	const [selectedSitting, setSelectedSitting] = useState(null);

	const [recusals, setRecusals] = useState([]);

	const [error, setError] = useState(null);

	const [matrix, setMatrix] = useState([]);

	const [selectedJudgesArray, setSelectedJudgesArray] = useState([]);

	const [selectionMode, setSelectionMode] = useState(true);

	const [populating, setPopulating] = useState(false);

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
		fancyButton: {
			background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
			border: 0,
			borderRadius: 3,
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			color: 'white',
			height: 48,
			padding: '0 30px',
		},
		box: {
			padding: '10px 0'
		}
	});



	const classes = useStyles();

	const days = [
		"Monday AM",
		"Monday PM",
		"Tuesday AM",
		"Tuesday PM",
		"Wednesday AM",
		"Wednesday PM",
		"Thursday AM",
		"Thursday PM",
		"Friday AM",
		"Friday PM",

	]

	useEffect(() => {
		if (numberOfCourtrooms > 5) {
			setNumberOfCourtrooms(5);
		}
		if (numberOfCourtrooms < 1) {
			setNumberOfCourtrooms(1);
		}
	}, [numberOfCourtrooms])



	const courtrooms = ['A', 'B', 'C', 'D', 'E'];

	// const populateSizeArray = () => {
	// 	setPopulating(true);
	// 	let clone = JSON.parse(JSON.stringify(matrix));
	// 	console.log("Populating size array, current length is ", clone.length, " and we need ", numberOfCourtrooms);
	// 	const currentLength = clone.length;
	// 	if (currentLength > numberOfCourtrooms) {
	// 		clone = clone.slice(0, numberOfCourtrooms);
	// 	} else if (currentLength === numberOfCourtrooms) {
	// 		//noop
	// 	} else {
	// 		while (clone.length < numberOfCourtrooms) {
	// 			clone.push(Array.from({ length: days.length }, () => { return { panelSize: 3 } }))
	// 			console.log("Pushed one on, now clone length is ", clone.length, " and we still need ", numberOfCourtrooms)
	// 		}
	// 	}
	// 	setMatrix(clone);
	// 	//setSelectedJudgesArray(JSON.parse(JSON.stringify(clone)));
	// 	setPopulating(false);
	// }

	// useEffect(() => {
	// 	console.log("Matrix has changed.")
	// 	const waigani = sittings?.find(s => s.court.name === 'Waigani');
	// 	if (!waigani) {
	// 		console.log("But waigani is ", waigani)
	// 		return;
	// 	}
	// 	setPopulating(false);
	// 	props.remote.put(`/admin/supreme-court-schedule/${waigani.id}/settings`, matrix);
	// }, [matrix]);

	useEffect(() => {
		props.remote.get("/admin/court-locations").then(setCourts);
		props.remote.get("/admin/sitting-judges").then(setJudges);
		props.remote.get("/admin/supreme-court-schedule").then(setSchedule);
		//populateSizeArray();
		setPopulating(false);
	}, [])

	useEffect(() => {
		checkMatrix();
	}, [])

	useEffect(() => {
		checkMatrix();
	}, [selectedMonth, schedule])

	const checkMatrix = () => {
		console.log("Selected month was changed, so firing use effect")
		const selectedSittings = schedule.filter(s => {
			const from = dayjs(s.fromDate);
			return from.year() === selectedMonth.year() && from.month() === selectedMonth.month();
		});
		setSittings(selectedSittings);
		console.log("Set sittings to ", selectedSittings)
		const waigani = selectedSittings?.find(s => s.court.name === 'Waigani');
		console.log("Found a waigani in the sittings ", waigani);
		if (!waigani) {
			return;
		}
		const waiganiSettings = waigani.settings;
		console.log("Which has settings ", waiganiSettings)
		if (waiganiSettings && waiganiSettings.length > 0) {
			setMatrix(JSON.parse(waiganiSettings));
			console.log("And set the matrix.");
		}
		const selectedJudges = waigani.selectedJudges;
		if (selectedJudges && selectedJudges.length > 0) {
			setSelectedJudgesArray(JSON.parse(selectedJudges));
			setSelectionMode(false);
		} else {
			setSelectedJudgesArray([])
			setSelectionMode(true)
		}
	}

	

	useEffect(() => {
		//TODO - update settings on server side.
//		populateSizeArray();
	}, [numberOfCourtrooms])

	const [panel, setPanel] = useState([]);

	const b64toBlob = (base64, type = 'application/pdf') => 
	fetch(`data:${type};base64,${base64}`).then(res => res.blob())

	const pdf = () => {
		const waigani = sittings?.find(s => s.court.name === 'Waigani');
		props.remote.get(`/empanelment/sitting/${waigani.id}/pdf`)
			.then(res => res.pdf)
			.then(base64 => {
				b64toBlob(base64).then(blob => {
					//var file = new Blob([response.data], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(blob);

					// create <a> tag dinamically
					const fileLink = document.createElement('a');
					fileLink.href = fileURL;

					// it forces the name of the downloaded file
					fileLink.download = 'empanelment.pdf';

					// triggers the click event
					fileLink.click();
					// console.log("Got a blob ", blob);
					// var file = window.URL.createObjectURL(blob);
					// window.location.assign(file);
				})
			})
	}

	const recuseJudge = (judge, reason) => {
		if (reason === "Available") {
			delete recusals[judge.id - 1];
		} else {
			recusals[judge.id - 1] = reason;
		}
		setRecusals(recusals.slice());
	}

	const shortenTitle = (judge) => {
		if (!judge.title) {
			return "";
		}
		return judge.title.split(" ").map((n) => n[0]).join('');
	}

	const formatJudge = (judge) => {
		if (!judge) {
			return null;
		}
		if (recusals && recusals[judge.id - 1]) {
			return judge && <p title="Has conflict of interest"><BlockIcon color='disabled' onClick={() => recuseJudge(judge, "Available")} /><span style={{ color: "lightgrey" }}>{judge.familyName + ", " + shortenTitle(judge)}</span></p>;
		} else {
			return judge && <p><CheckIcon color='success' onClick={() => recuseJudge(judge, "Conflict of interest")} />{judge.familyName + ", " + shortenTitle(judge)}</p>;
		}

	}

	const renderJudge = (judge) => {
		console.log("Rendering judge ", judge)
		console.log(judge.familyName + ", " + shortenTitle(judge))
		return judge.familyName + ", " + shortenTitle(judge)
	}

	const handleClose = (event, reason) => {
		console.log("handling close ", event, reason);
		if (reason === 'clickaway') {
		  return;
		}
		setError(false);
	};

	const generatePanels = () => {
		console.log("Generating panel")
		const availableJudges = waigani?.sittingJudges;

		props.remote.post(`/empanelment/sitting/${waigani.id}/generate`).then(j => {
			setSelectedJudgesArray(j);
			setSelectionMode(false)
		}).catch(e => {
			setSelectedJudgesArray([])
			setError("Unable to generate. Please contact Alison Holt")
		});

	}


	const regional = sittings && sittings.filter(s => s.court.name !== 'Waigani');
	const waigani = sittings.find(s => s.court.name === 'Waigani');

	const formatJudges = region => {
		return region.sittingJudges.map(j => `${j.title} ${j.familyName}`).sort((j1, j2) => j1.id < j2.id).join(", ");
	}


	const setPanelSize = (court, day, value) => {
		if (value > 7 || value < 1) {
			return;
		}
		console.log("Setting panel size for ", { "court": court, "day": day, "val": value })

		var newArray = matrix.map(function (arr) {
			return arr.slice();
		});

		newArray[court][day].panelSize = value;
		setMatrix(newArray)
	}


	const setCase = (court, day, value) => {
		var newArray = matrix.map(function (arr) {
			return arr.slice();
		});
		newArray[court][day].case = value;
		setMatrix(newArray)
	}


	const setPrimaryJudge = (court, day, judge) => {
		console.log("Setting primary judge for ", { court, day, judge })

		var newArray = matrix.map(function (arr) {
			return arr.slice();
		});

		newArray[court][day].primaryJudge = judge;
		setMatrix(newArray)
	}

	const displaySelectedJudges = (court, timeslot) => {
		if (!selectedJudgesArray || !selectedJudgesArray[timeslot]) {
			return null;
		}
		const selectedJudges = selectedJudgesArray[timeslot][court];
		if (!selectedJudges) {
			return "Unable to form panel, too few judges";
		}
		console.log("Selected judges is ", selectedJudges)
		if (selectedJudges.length === 0) {
			return "Unable to form panel, too few judges"
		}
		return selectedJudges.map(renderJudge).join(", ");
	}

	const setCase2 = (a, b, c, d) => {
		console.log("Calling set case 2 with ", { a, b, c, d })
	}

	const _setNumberOfCourtrooms = (num, waigani) => {
		props.remote.post(`/empanelment/sitting/${waigani.id}/courts/${num}`,{})
		.then(resp => {
			props.remote.get("/admin/supreme-court-schedule").then(setSchedule);
		})
		.catch(e=>setError("Error updating"));
	}

	if (populating) {
		return <Loader
			type="ThreeDots"
			color="#254A8F"
			height={30}
			width={100}
		/>
	}

	const activeCourtrooms = courtrooms.slice(0, numberOfCourtrooms);

	console.log("Triggering render")

	return (
		<>

			<DefaultSnackbar
				type={'error'}
				open={error}
				onClose={handleClose}
				message={error}
			/>
			<div className="empanel-outer">
				<header>Empanelment Tool</header>
				<div className="section">
					<header>
						<div className="number">1</div>
						Pool selection
					</header>
					<div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label="Sitting month"
								value={selectedMonth}
								views={["year", "month"]}
								onChange={(newValue) => {
									setSelectedMonth(newValue);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>

					</div>
				</div>
				{sittings && (sittings.length > 0) && <>
					<div className="section" style={{ paddingTop: "20px" }}>
						<p>
							Selected sitting runs from {dayjs(sittings[0].fromDate).format('DD/MM/YYYY')} to {dayjs(sittings[0].toDate).format('DD/MM/YYYY')}.
						</p>
						{regional && regional.map(r => <React.Fragment key={r.court.id}>
							<header>{r.court.name}</header>
							<p>Sitting judges</p>
							{formatJudges(r)}
						</React.Fragment>)}
						<header>Waigani</header>
						{selectionMode ? <span>Please configure settings below, and then click generate to pick a panel</span> : (<span>
							<Button onClick={pdf}>Show panel in printable PDF</Button> 
							<Button onClick={() => setSelectionMode(true)}>
							Change configuration settings
						</Button>
						</span>)}
						{/* <p>Number of courtrooms: <TextField
							autoFocus
							margin="dense"
							id="email"
							label="Number of courtrooms"
							type="number"
							fullWidth={true}
							defaultValue={numberOfCourtrooms}
							onChange={(e) => _setNumberOfCourtrooms(e.target.value, waigani)}
						/></p> */}
						<TableContainer component={Paper}>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Day</TableCell>
										{activeCourtrooms.map(c => <TableCell key={c}>
											Court {c}
										</TableCell>)}
									</TableRow>
								</TableHead>
								<TableBody>
									{days.map((d, idx) => <StyledTableRow key={idx}>
										<TableCell padding={selectionMode ? "none" : "normal"}>{d}</TableCell>
										{activeCourtrooms.map((c, cidx) => <TableCell key={`d${idx}_c${cidx}_cell`} padding="normal">
											{selectionMode && matrix.length > cidx && matrix[cidx].length > idx && <>
												<SittingOptions
													day={idx}
													court={cidx}
													renderJudge={renderJudge}
													currentVals={matrix[cidx][idx]}
													judges={judges}
													setPrimaryJudge={setPrimaryJudge}
													setPanelSize={setPanelSize}
													setCase={setCase}
													sitting={waigani}
													setError={setError}
												/>

											</>}
											{!selectionMode && displaySelectedJudges(cidx, idx)}
										</TableCell>)}
									</StyledTableRow>)}
								</TableBody>
							</Table>
						</TableContainer>
						{selectionMode && <Button onClick={generatePanels}>
							Generate panels
						</Button>}
						{!selectionMode && <Button onClick={() => setSelectionMode(true)}>
							Reselect
						</Button>}
						</div>

				</>}
			</div>
		</>

	);
}

export default withRemote(Empanelment);
